<template>
  <div class="main-wrapper">
    <title-bar
      title="Terms of Use"
      subtitle=""
    />
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 ">
                <div class="about-info">
                  <div class="about-desc">
                    <h2>
                      TERMS OF USE
                    </h2>
                    <p>
                      The Thriggle.com website is provided to you by Thriggle ("we" or "us"). This Terms of Use Agreement is a binding legal contract between you and us. Please read this Terms of Use Agreement; by using this service you accept its terms as well as any additional terms or conditions presented to you during the course of your use of our service. The Internet is an evolving medium; we may change the terms of this agreement from time to time. By continuing to use the service after we post such changes, you accept this agreement, as modified. We reserve the right to deny access to this website, or any service provided via this website, to anyone who violates this Terms of Use Agreement or who, in our judgment, interferes with the ability of others to enjoy this website, or infringes the rights of others. We invite you to send in your questions or comments about this website, or to bring to our attention any material you believe to be inaccurate. Please send such comments, including a copy of any material you wish to discuss to

                    </p>
                   <h6>Walter Hayes </h6> 
                    <h6>Thriggle </h6>
                    <i>
                      1510 Eldridge Pkwy.,<br>
                      Ste. 110-120 <br>
                      Houston, TX 77077 

                    </i>
                    <h2>
                      Privacy
                    </h2>
                    <p>We respect the privacy of the users of our website. Please take a moment to review our privacy policy. </p>

                    <h2>Intellectual Property Rights</h2>
                    <p>
                      Although we make this website freely accessible, we don't intend to give up our rights, or anyone else's rights, to the materials appearing on the service. The materials available through this website are the property of us and our affiliates or licensors, and are protected by copyright, trademark and other intellectual property laws. You are free to display and print for your personal, non-commercial use information you receive through this website. But you may not otherwise reproduce any of the materials without the prior written consent of the owner. You may not distribute copies of materials found on this website in any form (including by email or other electronic means) without prior written permission from the owner. Of course, you're free to encourage others to access the information themselves on our website and to tell them how to find it. Furthermore, you may not frame, or in-line link, any of the content of this website, or incorporate into another website or other service any of our
                       intellectual property. 
                    </p>
                    <h2>Patent Information</h2>
                    <p>
                      Thriggle might own one or more United States and international patents that apply to the Site. 
                    </p>
                    <h2>
                      Your License to Us
                    </h2>
                    <p>
                      By submitting material to us, you are representing that you are the owner of the material, or are making your submission with the express consent of the owner. By submitting material to any public posting areas of this website, such as any reviews or comments, you agree that we (including our partners, agents, affiliates and service providers (collectively, "Third Party Providers"), may reproduce, modify, and distribute such material as we see fit in any medium and for any purpose. You also agree that we, including our Third Party Providers, may identify you as the author of any of your postings by name, email address or screen name as we see appropriate. We also reserve the right (but assume no obligation) to delete, move, or edit any postings that come to our attention that we consider unacceptable or inappropriate, whether for legal or
                       other reasons. 
                    </p>
                    <h2>Links</h2>
                    <p>
                      We welcome links to our platform. You are free to establish a hypertext link to this site so long as the link does not state or imply any sponsorship of your site by us. 

                    </p>
                    <h2>
                      Trade Marks
                    </h2>
                    <p>
                      You may not use any trademark or service mark appearing on this website without the prior written consent of Thriggle. 
                    </p>
                    <h2>
                      Notice of Copywrite Infringement
                    </h2>
                    <p>
                      If you are an owner of intellectual property who believes your intellectual property has been improperly posted or distributed via our website, please notify by sending a notice by U.S. Mail to Walter Hayes, 1510 Eldridge Pkwy, Ste. 110 – 120, Houston, TX 77077. You should only send a written notice if you believe that your work has been used or copied in a manner that infringes your intellectual property rights and such infringement has occurred on this website. We will not respond to any other inquiries received by way of our mailing address. Such inquiries should be made via the feedback procedure described on our site. 

                    </p>
                    <h2>
                      Disclaimers
                    </h2>
                    <p>
                      Use at Your Own Risk. We provide the material available through this website for informational purposes only. You may only use the material and the services available through this website for your personal and non-commercial use. We try to ensure that information we post to this site is both timely and accurate, and that the services offered are reliable. Yet despite our efforts, errors may occur from time to time. Before you act on any information you've found on our site, you should confirm any facts that are important to your decision. IF YOU RELY ON ANY INFORMATION OR SERVICE AVAILABLE THROUGH THIS WEBSITE, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE OR LOSS YOU MAY INCUR THAT RESULTS FROM YOUR USE OF ANY SERVICE OR ANY MATERIAL AND/OR DATA DOWNLOADED FROM OR OTHERWISE PROVIDED THROUGH THIS WEBSITE. 
Quality of Good and Services. We are not responsible for, and cannot guarantee the quality, safety or performance of, goods and services provided by our advertisers or by others who advertise on sites that we link to. A business listing on our site does not constitute our endorsement of the business featured in the listing or of any product, service or other material offered through such business. Similarly, a link to another website does not constitute our endorsement of that site or of any product, service or other material advertised on or offered through such site. 
No Warranties. THIS WEBSITE AND ALL MATERIALS, INFORMATION AND SERVICES AVAILABLE THROUGH IT, ARE PROVIDED TO YOU "AS IS," "WITH ALL FAULTS," AND "AS AVAILABLE." WE, OUR AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE MATERIALS, INFORMATION AND SERVICES AVAILABLE THROUGH THE WEBSITE, NOR DO WE GUARANTEE THAT THE MATERIALS, INFORMATION OR SERVICES WILL BE ERROR-FREE, OR CONTINUOUSLY AVAILABLE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. 

                    </p>
                    <h2>
                      Limitations on Our Liability
                    </h2>
                    <p>
                      UNDER NO CIRCUMSTANCES SHALL WE, OR OUR AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES ARISING OUT OF USE OF THIS WEBSITE OR ANY SERVICE AVAILABLE THROUGH THIS WEBSITE, INCLUDING, WITHOUT LIMITATION, LIABILITY FOR CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES, EVEN IF WE ARE ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES OF DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES, AGENTS AND LICENSORS IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW.) YOU AGREE THAT OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES, AGENTS AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM IN ANY WAY CONNECTED TO THE SERVICEs SHALL NOT EXCEED THE AMOUNT YOU PAID FOR THE USE OF THE SERVICE. 

                    </p>
                    <h2>
                      Use of Sites and Services
                    </h2>
                    <h6>Your permission to use the Sites and/or Services is conditioned upon your agreement that you: </h6>
                    <ul>
                      <li>	are 18 years of age or older.</li>
                      <li>	will comply with these Terms.</li>
                      <li>	are able to form a binding contract with us.</li>
                      <li>	are not prohibited by law from accessing our Sites and/or Services or have not previously been banned, terminated or otherwise denied access to our Sites and/or Services by us.</li>
                      <li>	are not acting on behalf of a person whose access to our Sites and/or Services has been previously terminated or otherwise denied by us.</li>
                      <li>	will not impersonate another user of the Sites and/or Services.
                      <li>	the information you supply during registration process will be accurate and complete and that you will not register under the name of, nor attempt to enter the service under the name of, another person.</li>
                      <li>	We reserve the right to reject or terminate any user name that, in our judgment, we deem offensive.</li>
                      <li>	You will be responsible for preserving the confidentiality of your password and will notify us at privacy@thriggle.com of any known or suspected unauthorized use of your account.</li>
                    </ul>
                    Furthermore, we may provide a variety of services on this website through which you can directly interact with others, such as events, email services, reviews and user-submitted advertising, and other public posting areas. We will not, in the ordinary course of business, review private electronic messages that are not addressed to us. However, we reserve the right to do so and to use any other forms of information available to us by virtue of your use of our website in order to comply with the law, to enforce our Terms of Use Agreement, or to protect the rights, property or safety of visitors to our website, our customers, the public or Thriggle, and its employees, agents, partners and affiliates

                    <p>
                      <h2>Termination</h2>
                    <p>
                      We reserve the right to deny access to Thriggle to anyone who violates this Terms of Use Agreement or who, in our judgment, interferes with the ability of others to enjoy our website or infringes the rights of others. We also reserve the right (but assume no obligation) to delete, move, or edit any postings that come to our attention that we consider unacceptable or inappropriate, whether for legal or other reasons. We will comply with the requirements of the law regarding disclosure of any messages to others, and we may disclose messages in response to requests from law enforcement agencies without prior notice to the affected Communication Service user. 

                    </p>
                    <h6>We reserve the right to deny access to Thriggle to anyone who violates this Terms of Use Agreement or who, in our judgment, interferes with the ability of others to enjoy our website or infringes the rights of others. We also reserve the right (but assume no obligation) to delete, move, or edit any postings that come to our attention that we consider unacceptable or inappropriate, whether for legal or other reasons. We will comply with the requirements of the law regarding disclosure of any messages to others, and we may disclose messages in response to requests from law enforcement agencies without prior notice to the affected Communication Service user. 
                    </h6>
                    <ul>
                      <li>	Uploading, posting, emailing, transmitting or otherwise making available any content that is unlawful, harmful, threatening, abusive, libelous, or obscene</li>
                      <li>	Impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity</li>
                      <li>	Forging headers or otherwise manipulating identifiers in a manner that disguises the origin of any content you transmit through any Communications Service</li>
                      <li>	Uploading, posting, emailing, transmitting or otherwise making available any content that you do not have a right to make available under any law or under any contractual or fiduciary relationship (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements)</li>
                      <li>	Uploading, posting, emailing, transmitting or otherwise making available any content that infringes any patent, trademark, trade secret, copyright or other proprietary right of any party</li>
                      <li>	Uploading, posting, emailing, transmitting or otherwise making available any unsolicited or unauthorized advertising, promotional materials, or any other form of solicitation, without our express written approval</li>
                      <li>	Gathering for the purpose of "spamming" any email addresses that users post in our public posting areas</li>
                      <li>	Uploading, posting, emailing, transmitting or otherwise making available any content or material that contains software viruses, worms or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications or other equipment, or to cause a security breach of such software, hardware or telecommunications or other equipment</li>
                      <li>	Uploading or posting any off-topic or irrelevant material</li>
                      <li>	Interfering with or disrupting any servers or networks used to provide the Communications Services, or disobeying any requirements, procedures, policies or regulations of the networks we use to provide the Communications Services</li>
                      <li>	Violating any applicable local, state, national or international law, including, but not limited to all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside</li>
                      <li>	"Stalking" or otherwise harassing another</li>
                      <li>	Instigating or encouraging others to commit illegal activities or cause injury or property damage to any person</li>
                      <li>	Collecting or storing personal data about other users</li>
                      <li>	Gaining unauthorized access to our website, or any account, computer system, or network connected to this website, by means such as hacking, password mining or other illicit means</li>
                      <li>	Obtaining or attempting to obtain any materials or information through any means not intentionally made available through this website</li>

                    </ul>
                    <p>
                      Participating merchants may terminate their agreement any time by deleting their listing through their control panel, or by notifying Thriggle at privacy@thriggle.com. Payment made for the month is considered fully earned with no return. 
Thriggle may, at any time, may terminate this Agreement with you if: (i) You have breached any provision of this Agreement or you do not comply with the Agreement; (ii) Thriggle is required by law to do so; (iii) the partner with whom Thriggle has offered the Services to you has terminated its relationship with Thriggle or ceased to offer the Services to you; (iv) Thriggle no longer provides Services in the country in which you reside; or (v) Thriggle determines that the provision of Services to you is no longer commercially viable. Notwithstanding anything contrary in these Terms, Thriggle reserves the right to discontinue any product or service at any time and at its sole discretion. Without limiting any of the foregoing, Thriggle may immediately terminate or suspend any User IDs, Accounts or passwords in the event of any conduct by you which Thriggle, in its sole discretion, considers to be unacceptable or in the event of any breach by you of this Agreement. In the event of termination, you agree to immediately cease access to our Sites and Services. Any Voucher issued prior to termination will be honored according to its terms and the terms of this Agreement specifically applicable to such Voucher. These Terms will survive termination of this Agreement. 

                    </p>
                    <h2>
                      Spam
                    </h2>
                    <p>
                      We will immediately terminate any account which we believe is being used to transmit, or is otherwise connected with, any spam or other unsolicited bulk email. In addition, because damages are often difficult to quantify, if actual damages cannot be reasonably calculated, then you agree to pay us liquidated damages of $2 for each item of spam or unsolicited bulk email transmitted from, or otherwise connected with, your account. Otherwise you agree to pay our actual damages to the extent such actual damages can be reasonably calculated. 

                    </p>
                    <h2>
                      Viruses
                    </h2>
                    <p>
                      Because of the volume of email messages sent by us and users of this website, we cannot and do not always monitor such email messages and attachments. You acknowledge and agree that such email messages may contain viruses, worms or other harmful components. You also acknowledge and agree that you are solely responsible for screening the email messages and attachments for viruses, worms and other harmful components. 

                    </p>
                    <h2>
                      Indemnify
                    </h2>
                    <p>
                      You agree to indemnify and hold us and our affiliates, agents and licensors, harmless from any claim that may arise from your use of any Communication Service available through this website. 

                    </p>
                    <h2>
                      Jurisdiction
                    </h2>
                    <p>
                      This Terms of Use Agreement has been made in and shall be construed in accordance with the laws of the State of Texas. By using this service, you consent to the exclusive jurisdiction of the state and federal courts in Harris County, Texas in all disputes arising out of or relating to this agreement or this website
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
   created () {
      document.title = "Terms of Use - Thriggle";
  },
	components: {
		TitleBar: TitleBar
	}
};
</script>
